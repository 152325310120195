import React, { useState, useEffect } from "react";
import { Device } from "@twilio/voice-sdk";
import axios from "axios";
import Papa from "papaparse";
import moment from "moment-timezone";
import "./App.css";
import { supabase } from "./supabaseClient"; // Import Supabase client
import { ToastContainer, toast } from "react-toastify"; // Import Toastify
import "react-toastify/dist/ReactToastify.css";

// Timezone to EST mapping and call time adjustment (relative to EST)
const timezoneInfo = {
  Eastern: { start: 9, end: 20 }, // 9 AM - 8 PM EST
  Central: { start: 10, end: 21 }, // 10 AM - 9 PM EST
  Mountain: { start: 11, end: 22 }, // 11 AM - 10 PM EST
  Pacific: { start: 12, end: 23 }, // 12 PM - 11 PM EST
  Alaska: { start: 13, end: 0 }, // 1 PM - 12 AM EST
  Hawaii: { start: 14, end: 1 }, // 2 PM - 1 AM EST
};

// State to Timezone mapping
const stateToTimezone = {
  AL: "Central",
  AK: "Alaska",
  AZ: "Mountain",
  AR: "Central",
  CA: "Pacific",
  CO: "Mountain",
  CT: "Eastern",
  DE: "Eastern",
  FL: "Eastern",
  GA: "Eastern",
  HI: "Hawaii",
  ID: "Mountain",
  IL: "Central",
  IN: "Eastern",
  IA: "Central",
  KS: "Central",
  KY: "Eastern",
  LA: "Central",
  ME: "Eastern",
  MD: "Eastern",
  MA: "Eastern",
  MI: "Eastern",
  MN: "Central",
  MS: "Central",
  MO: "Central",
  MT: "Mountain",
  NE: "Central",
  NV: "Pacific",
  NH: "Eastern",
  NJ: "Eastern",
  NM: "Mountain",
  NY: "Eastern",
  NC: "Eastern",
  ND: "Central",
  OH: "Eastern",
  OK: "Central",
  OR: "Pacific",
  PA: "Eastern",
  RI: "Eastern",
  SC: "Eastern",
  SD: "Central",
  TN: "Central",
  TX: "Central",
  UT: "Mountain",
  VT: "Eastern",
  VA: "Eastern",
  WA: "Pacific",
  WV: "Eastern",
  WI: "Central",
  WY: "Mountain",
};

function Dialer() {
  // State for Twilio configuration
  const [contacts, setContacts] = useState([]);
  const [status, setStatus] = useState("Initializing...");
  const [device, setDevice] = useState(null);
  const [activeConnection, setActiveConnection] = useState(null);
  const [callDuration, setCallDuration] = useState(0);
  const [callTimer, setCallTimer] = useState(null);
  const [saveStatus, setSaveStatus] = useState("All changes saved.");
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  const [currentTab, setCurrentTab] = useState("Eastern");
  const [showPopup, setShowPopup] = useState(false);
  const [currentContact, setCurrentContact] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authError, setAuthError] = useState(null);
  const [twilioConfig, setTwilioConfig] = useState({
    account_sid: "",
    auth_token: "",
    phone_number: "",
    api_key_sid: "",
    api_key_secret: "",
    app_sid: "",
  });

  const handleExportContacts = () => {
    if (!currentUser) {
      alert("Please log in to export contacts.");
      return;
    }

    // Make a GET request to export contacts as CSV
    const url = `http://localhost:5000/export-contacts?user_id=${currentUser.id}`;
    window.open(url, "_blank"); // Open the CSV download in a new tab
  };

  // Fetch Twilio config from Supabase for the logged-in user
  const fetchTwilioConfig = async (userId) => {
    try {
      // Check if the userId is passed correctly
      if (!userId) {
        console.error("User ID is missing, cannot fetch Twilio configuration.");
        return;
      }

      const { data, error } = await supabase
        .from("twilio_config")
        .select("*")
        .eq("user_id", userId);

      // Check if there's an error in the response
      if (error) {
        console.error("Error fetching Twilio configuration:", error.message);
        toast.error("Failed to load Twilio configuration.");
        return;
      }

      if (!data || data.length === 0) {
        console.error("No Twilio configuration found for user ID:", userId);
        toast.error("No Twilio configuration found.");
        return;
      }

      if (data.length > 1) {
        console.warn(
          `Multiple Twilio configurations found for user ID ${userId}, using the first one.`
        );
      }

      // Use the first Twilio config if multiple rows are returned
      const twilioConfig = data[0];

      // Log the fetched data for debugging purposes
      console.log("Twilio configuration fetched:", twilioConfig);

      // Populate the state with fetched configuration
      setTwilioConfig({
        account_sid: twilioConfig.account_sid || "",
        auth_token: twilioConfig.auth_token || "",
        phone_number: twilioConfig.phone_number || "",
        api_key_sid: twilioConfig.api_key_sid || "",
        api_key_secret: twilioConfig.api_key_secret || "",
        app_sid: twilioConfig.app_sid || "",
      });
    } catch (error) {
      // Catch any unexpected errors
      console.error("Unexpected error fetching Twilio configuration:", error);
      toast.error("Failed to load Twilio configuration.");
    }
  };

  // Fetch user info and Twilio config when component mounts
  useEffect(() => {
    const fetchUser = async () => {
      const { data: user } = await supabase.auth.getUser();
      setCurrentUser(user?.user);
      if (user?.user) {
        setIsLoggedIn(true);
        setCurrentUser(user.user);
        fetchTokenAndContacts(user.user.id); // Pass userId to the function
        fetchTwilioConfig(user.user.id); // Fetch Twilio configuration
        fetchContacts(user.user.id); // Fetch contacts from Supabase
      }
    };
    fetchUser();
    document.body.className = theme;
  }, [theme]);

  const handleTwilioConfigChange = (e) => {
    const { name, value } = e.target;
    setTwilioConfig((prevConfig) => ({
      ...prevConfig,
      [name]: value, // Update the specific field in twilioConfig
    }));
  };

  const handleSaveTwilioConfig = async (e) => {
    e.preventDefault();

    try {
      // Ensure the twilioConfig object contains the latest form data
      const configToSave = {
        account_sid: twilioConfig.account_sid,
        auth_token: twilioConfig.auth_token,
        phone_number: twilioConfig.phone_number,
        api_key_sid: twilioConfig.api_key_sid,
        api_key_secret: twilioConfig.api_key_secret,
        app_sid: twilioConfig.app_sid,
      };

      await axios.post("http://localhost:5000/twilio-config", {
        twilioConfig: configToSave, // Pass the config data
        user_id: currentUser?.id, // Ensure user ID is passed correctly
      });

      alert("Twilio configuration saved successfully!");
    } catch (error) {
      console.error("Failed to save Twilio config", error);
      alert("Error saving Twilio configuration.");
    }
  };

  //Set up light and dark theme
  const toggleTheme = () => {
    setTheme((prevTheme) => {
      const newTheme = prevTheme === "light" ? "dark" : "light";
      document.body.className = newTheme; // This will apply the theme class to the body
      return newTheme;
    });
  };

  const closePopup = () => {
    // Hang up the call if it's active
    if (activeConnection) {
      handleHangUp(); // Ensure the call is hung up when the popup is closed
    }
    setShowPopup(false); // Close the popup
  };

  const [newContact, setNewContact] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    state: "",
    beneficiary: "",
    date_of_birth: "",
    note: "",
  });

  const formatPhoneNumber = (phone) => {
    // Ensure the phone number has exactly 10 digits
    const cleaned = ("" + phone).replace(/\D/g, ""); // Remove non-digit characters
    if (cleaned.length === 10) {
      const part1 = cleaned.slice(0, 3);
      const part2 = cleaned.slice(3, 6);
      const part3 = cleaned.slice(6, 10);
      return `+1 ${part1}-${part2}-${part3}`;
    }
    return phone; // Return original if it doesn't have 10 digits
  };

  const formatDOB = (dob) => {
    return moment(dob).format("M/D/YYYY"); // Format DOB as MM/DD/YYYY
  };

  const handleNewContactChange = (e) => {
    const { name, value } = e.target;
    setNewContact((prevContact) => ({ ...prevContact, [name]: value }));
  };

  const handleAddNewContact = async (e) => {
    e.preventDefault();

    const formattedPhone = formatPhoneNumber(newContact.phone_number); // Format the phone number
    const formattedDOB = moment(newContact.date_of_birth).format("M/D/YYYY"); // Format the DOB as MM/DD/YYYY

    try {
      const { error } = await supabase.from("contacts").insert([
        {
          ...newContact,
          phone_number: formattedPhone, // Save formatted phone number
          date_of_birth: formattedDOB, // Save formatted date of birth
          user_id: currentUser.id,
          call_count: 0,
        },
      ]);

      if (error) throw error;

      setNewContact({
        first_name: "",
        last_name: "",
        phone_number: "",
        state: "",
        beneficiary: "",
        date_of_birth: "",
        note: "",
      });

      toast.success("New contact added successfully!");
      fetchContacts(currentUser.id); // Refresh the contacts list
    } catch (error) {
      toast.error("Failed to add new contact");
      console.error("Error adding new contact:", error);
    }
  };

  const totalDials = contacts.reduce(
    (total, contact) => total + (contact.call_count || 0),
    0
  );

  useEffect(() => {
    const fetchUser = async () => {
      const { data: user } = await supabase.auth.getUser();
      setCurrentUser(user?.user);
      if (user?.user) {
        setIsLoggedIn(true);
        await fetchTokenAndContacts(user.user.id); // Pass userId to fetchTokenAndContacts
        await fetchContacts(user.user.id); // Fetch contacts as well
      }
    };
    fetchUser();
    document.body.className = theme;
  }, [theme]);

  const handleSignUp = async () => {
    const { error } = await supabase.auth.signUp({
      email,
      password,
    });
    if (error) {
      setAuthError(error.message);
    } else {
      setAuthError(null);
      alert("User registered! Please log in.");
    }
  };

  const handleLogin = async () => {
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (error) {
      setAuthError(error.message);
    } else {
      setAuthError(null);
      const { data: user } = await supabase.auth.getUser();
      setCurrentUser(user.user);
      setIsLoggedIn(true);
      fetchTokenAndContacts(user.user.id);
    }
  };

  // Function to delete a contact
  const handleDeleteContact = async (contactId) => {
    try {
      const { error } = await supabase
        .from("contacts")
        .delete()
        .eq("id", contactId);
      if (error) throw error;

      const updatedContacts = contacts.filter((c) => c.id !== contactId);
      setContacts(updatedContacts);

      toast.success("Contact deleted successfully");
    } catch (error) {
      toast.error("Failed to delete contact");
      console.error("Error deleting contact:", error);
    }
  };

  const handleFinancialQuestionChange = (e, field) => {
    const updatedContact = { ...currentContact, [field]: e.target.value };
    const updatedContacts = contacts.map((c) =>
      c.id === currentContact.id ? updatedContact : c
    );
    setContacts(updatedContacts);
    setCurrentContact(updatedContact);
    handleSaveChanges(updatedContact);
  };

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) console.error("Error logging out:", error);
    else {
      setIsLoggedIn(false);
      setCurrentUser(null);
    }
  };

  const fetchTokenAndContacts = async (userId) => {
    try {
      const tokenResponse = await axios.get("http://localhost:5000/token", {
        params: {
          user_id: userId, // Pass the userId in the request
        },
      });
      const { token } = tokenResponse.data;
      const twilioDevice = new Device(token, { debug: true });
      setDevice(twilioDevice);

      twilioDevice.on("ready", () => setStatus("Ready to make calls"));
      twilioDevice.on("error", (error) => setStatus(`Error: ${error.message}`));

      twilioDevice.on("connect", (connection) => {
        setStatus("Call Connected");
        setActiveConnection(connection);

        const startTime = Date.now();
        const timer = setInterval(() => {
          setCallDuration(Math.floor((Date.now() - startTime) / 1000)); // Track call duration
        }, 1000);
        setCallTimer(timer);
      });

      twilioDevice.on("disconnect", () => {
        setStatus("Call Ended");
        setActiveConnection(null);
        clearInterval(callTimer);
        setCallTimer(null);
        setCallDuration(0);
      });
    } catch (error) {
      console.error("Failed to fetch token or load contacts:", error);
      setStatus("Failed to initialize.");
    }
  };

  const fetchContacts = async (userId) => {
    try {
      const { data, error } = await supabase
        .from("contacts")
        .select("*")
        .eq("user_id", userId);

      if (error) throw error;
      setContacts(data || []); // Set the fetched contacts into the state
    } catch (error) {
      console.error("Failed to load contacts:", error);
    }
  };

  // Function to handle file upload and show toast
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    Papa.parse(file, {
      header: true,
      complete: async (results) => {
        const contactsWithInitialValues = results.data.map((contact) => ({
          first_name: contact.first_name.trim(),
          last_name: contact.last_name.trim(),
          phone_number: contact.phone_number.trim(),
          state: contact.state.trim(),
          beneficiary: contact.beneficiary.trim(),
          date_of_birth: contact.date_of_birth.trim(),
          note: contact.note ? contact.note.trim() : "", // Ensure empty strings if note is missing
          call_count: contact.call_count ? parseInt(contact.call_count, 10) : 0,
          last_called: contact.last_called.trim(),
          tobacco_use: contact.tobacco_use.trim(),
          heart_condition: contact.heart_condition.trim(),
          stents_in_heart: contact.stents_in_heart.trim(),
          cancer_history: contact.cancer_history.trim(),
          diabetes_history: contact.diabetes_history.trim(),
          diabetes_insulin: contact.diabetes_insulin.trim(),
          pain_medication: contact.pain_medication.trim(),
          blood_pressure: contact.blood_pressure.trim(),
          breathing_issues: contact.breathing_issues.trim(),
          thyroid_issues: contact.thyroid_issues.trim(),
          anxiety_depression: contact.anxiety_depression.trim(),
          recent_hospitalizations: contact.recent_hospitalizations.trim(),
          burial_plan: contact.burial_plan.trim(),
          coverage_purpose: contact.coverage_purpose.trim(),
          delivery_address: contact.delivery_address.trim(),
          user_id: currentUser?.id,
        }));

        console.log("Parsed contacts:", contactsWithInitialValues);

        try {
          const { error } = await supabase
            .from("contacts")
            .insert(contactsWithInitialValues);

          if (error) throw error;

          setSaveStatus("Contacts uploaded successfully.");
          fetchContacts(currentUser.id);
          toast.success("Contacts uploaded successfully");
        } catch (error) {
          setSaveStatus("Failed to upload contacts.");
          toast.error("Failed to upload contacts");
          console.error("Error uploading contacts:", error);
        }
      },
    });
  };

  // Function to save changes to all relevant fields
  const handleSaveChanges = async (updatedContact) => {
    try {
      const { error } = await supabase
        .from("contacts")
        .update({
          // ensure correct field names as per your schema
          note: updatedContact.note,
          call_count: updatedContact.call_count,
          tobacco_use: updatedContact.tobacco_use, // snake_case as per schema
          heart_condition: updatedContact.heart_condition, // snake_case
          stents_in_heart: updatedContact.stents_in_heart, // snake_case
          cancer_history: updatedContact.cancer_history, // snake_case
          diabetes_history: updatedContact.diabetes_history, // snake_case
          diabetes_insulin: updatedContact.diabetes_insulin, // snake_case
          pain_medication: updatedContact.pain_medication, // snake_case
          blood_pressure: updatedContact.blood_pressure, // snake_case
          breathing_issues: updatedContact.breathing_issues, // snake_case
          thyroid_issues: updatedContact.thyroid_issues, // snake_case
          anxiety_depression: updatedContact.anxiety_depression, // snake_case
          recent_hospitalizations: updatedContact.recent_hospitalizations, // snake_case
          burial_plan: updatedContact.burial_plan, // snake_case
          coverage_purpose: updatedContact.coverage_purpose, // snake_case
          delivery_address: updatedContact.delivery_address, // snake_case
          last_called: updatedContact.last_called,
        })
        .eq("id", updatedContact.id);

      if (error) {
        console.error("Error saving to Supabase:", error);
        throw new Error(error.message);
      }

      setSaveStatus("All changes saved.");
    } catch (error) {
      setSaveStatus("Failed to save changes.");
      console.error("Error saving contact:", error);
    }
  };

  const getTimezone = (state) => {
    return stateToTimezone[state] || "Unknown";
  };

  const contactsByTimezone = contacts.reduce((acc, contact) => {
    const timezone = getTimezone(contact.state || contact.State);
    const birthDate = moment(
      contact.date_of_birth || contact["Date of Birth"],
      "MM-DD-YYYY"
    );
    const currentMonth = moment().month();
    const today = moment();

    if (!acc[timezone]) acc[timezone] = [];
    acc[timezone].push(contact);

    if (birthDate.isValid()) {
      if (!acc["Birthdays"]) acc["Birthdays"] = [];
      if (
        birthDate.month() === currentMonth &&
        birthDate.date() >= today.date()
      ) {
        acc["Birthdays"].push(contact);
      }
    }

    return acc;
  }, {});

  const handleDial = async (contact) => {
    if (!device || !contact.phone_number) {
      setStatus("Please enter a valid phone number.");
      return;
    }
    try {
      const params = { To: contact.phone_number, userId: currentUser.id };
      const connection = await device.connect({ params });
      setActiveConnection(connection);

      // Increment the call count and update the current contact for the overlay
      const updatedContact = {
        ...contact,
        call_count: (contact.call_count || 0) + 1,
        last_called: new Date().toISOString(),
      };
      setCurrentContact(updatedContact); // Update contact for popup with incremented call count
      setShowPopup(true); // Show popup

      // Update the contact's call count in the global contact list
      const updatedContacts = contacts.map((c) =>
        c.id === contact.id ? updatedContact : c
      );
      setContacts(updatedContacts);

      handleSaveChanges(updatedContact); // Save the updated contact in the database
    } catch (error) {
      console.error("Error during dial", error);
      setStatus("Failed to initiate the call");
    }
  };

  const handleHangUp = () => {
    if (activeConnection) {
      activeConnection.disconnect();
      setActiveConnection(null);
    }
  };

  const handleViewDetails = (contact) => {
    setCurrentContact(contact);
    setShowPopup(true);
  };

  const resetCallCount = async (contact) => {
    const updatedContact = { ...contact, call_count: 0 };
    const updatedContacts = contacts.map((c) =>
      c.id === contact.id ? updatedContact : c
    );
    setContacts(updatedContacts);
    handleSaveChanges(updatedContact);
  };

  const updateNotes = (e) => {
    const updatedContact = { ...currentContact, note: e.target.value };
    const updatedContacts = contacts.map((c) =>
      c.id === currentContact.id ? updatedContact : c
    );
    setContacts(updatedContacts);
    setCurrentContact(updatedContact);
    handleSaveChanges(updatedContact);
  };

  const calculateAge = (dob) => {
    if (!dob) return "";
    const birthDate = moment(dob, "MM-DD-YYYY");
    if (!birthDate.isValid()) return "";
    return moment().diff(birthDate, "years") || "";
  };

  if (!isLoggedIn) {
    return (
      <div className="auth-container">
        <h2>Please Log In or Sign Up</h2>
        {authError && <p className="error">{authError}</p>}
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div>
          <button onClick={handleLogin}>Log In</button>
          <button onClick={handleSignUp}>Sign Up</button>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      {/* Add ToastContainer to display notifications */}
      <ToastContainer />
      <div className="theme-toggle">
        <button onClick={toggleTheme}>
          Switch to {theme === "light" ? "Dark" : "Light"} Mode
        </button>
      </div>
      <div className="container">
        <h2 className="title">Twilio Configuration</h2>

        <form onSubmit={handleSaveTwilioConfig}>
          <div>
            <label>Twilio Account SID</label>
            <input
              type="text"
              name="account_sid"
              value={twilioConfig.account_sid}
              onChange={handleTwilioConfigChange}
              placeholder="ACXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
            />
          </div>

          <div>
            <label>Twilio Auth Token</label>
            <input
              type="password"
              name="auth_token"
              value={twilioConfig.auth_token}
              onChange={handleTwilioConfigChange}
              placeholder="Your Auth Token"
            />
          </div>

          <div>
            <label>Twilio Phone Number</label>
            <input
              type="text"
              name="phone_number"
              value={twilioConfig.phone_number}
              onChange={handleTwilioConfigChange}
              placeholder="+1XXXXXXXXXX"
            />
          </div>

          <div>
            <label>Twilio API Key SID</label>
            <input
              type="text"
              name="api_key_sid"
              value={twilioConfig.api_key_sid}
              onChange={handleTwilioConfigChange}
              placeholder="SKXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
            />
          </div>

          <div>
            <label>Twilio API Key Secret</label>
            <input
              type="password"
              name="api_key_secret"
              value={twilioConfig.api_key_secret}
              onChange={handleTwilioConfigChange}
              placeholder="Your API Key Secret"
            />
          </div>

          <div>
            <label>Twilio App SID</label>
            <input
              type="text"
              name="app_sid"
              value={twilioConfig.app_sid}
              onChange={handleTwilioConfigChange}
              placeholder="APXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
            />
          </div>

          <button type="submit">Save Twilio Configuration</button>
        </form>
      </div>

      <h2 className="title">Dialer App</h2>

      <div className="add-contact-form">
        <h3>Add New Contact</h3>
        <form onSubmit={handleAddNewContact}>
          <input
            type="text"
            name="first_name"
            placeholder="First Name"
            value={newContact.first_name}
            onChange={handleNewContactChange}
          />
          <input
            type="text"
            name="last_name"
            placeholder="Last Name"
            value={newContact.last_name}
            onChange={handleNewContactChange}
          />
          <input
            type="text"
            name="phone_number"
            placeholder="Phone Number"
            value={newContact.phone_number}
            onChange={handleNewContactChange}
          />
          <input
            type="text"
            name="state"
            placeholder="State"
            value={newContact.state}
            onChange={handleNewContactChange}
          />
          <input
            type="text"
            name="beneficiary"
            placeholder="Beneficiary"
            value={newContact.beneficiary}
            onChange={handleNewContactChange}
          />
          <input
            type="date"
            name="date_of_birth"
            placeholder="Date of Birth"
            value={newContact.date_of_birth}
            onChange={handleNewContactChange}
          />
          <textarea
            name="note"
            placeholder="Notes"
            value={newContact.note}
            onChange={handleNewContactChange}
          ></textarea>
          <button type="submit">Add Contact</button>
        </form>
      </div>

      <div id="total-dials-container" className="total-dials">
        <p>Total Dials Made: {totalDials}</p>
        <button onClick={handleLogout}>Logout</button>
        <button onClick={handleExportContacts}>Export Contacts as CSV</button>
      </div>

      <div className="timezone-tabs">
        {Object.keys(contactsByTimezone)
          .filter((key) => key !== "Birthdays") // This will remove the "Birthdays" tab
          .map((timezone) => (
            <div key={timezone} className="timezone-tab">
              <button
                onClick={() => setCurrentTab(timezone)}
                className={currentTab === timezone ? "active-tab" : ""}
              >
                {timezone} Timezone
              </button>
              <p>{`Call between ${timezoneInfo[timezone]?.start || "N/A"} - ${
                timezoneInfo[timezone]?.end || "N/A"
              } EST`}</p>
            </div>
          ))}
        <div className="timezone-tab">
          <button
            onClick={() => setCurrentTab("Birthdays")}
            className={currentTab === "Birthdays" ? "active-tab" : ""}
          >
            Birthdays This Month
          </button>
        </div>
      </div>

      <div>Status: {status}</div>
      <div>Save Status: {saveStatus}</div>
      <input type="file" accept=".csv" onChange={handleFileUpload} />
      <table>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Phone Number</th>
            <th>State</th>
            <th>Beneficiary</th>
            <th>DOB / Age</th>
            <th>Notes</th>
            <th>Call Count</th>
            <th>Last Called</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {contactsByTimezone[currentTab]?.map((contact, index) => (
            <tr
              key={index}
              className={contact.call_count >= 3 ? "grayed-out" : ""}
            >
              <td>{contact.first_name || contact["First Name"]}</td>
              <td>{contact.last_name || contact["Last Name"]}</td>
              <td>{contact.phone_number || contact["Phone Number"]}</td>
              <td>{contact.state || contact.State}</td>
              <td>{contact.beneficiary || contact.Beneficiary}</td>
              <td>
                {contact.date_of_birth || contact["Date of Birth"]} (
                {calculateAge(
                  contact.date_of_birth || contact["Date of Birth"]
                )}
                )
              </td>
              <td>{contact.note || contact.Note}</td>
              <td>{contact.call_count || 0}</td>
              <td>{contact.last_called}</td>
              <td>
                <button
                  onClick={() => handleDial(contact)}
                  disabled={contact.call_count >= 3}
                >
                  Dial
                </button>
                <button onClick={() => handleViewDetails(contact)}>
                  View Details
                </button>
                {contact.call_count >= 3 && (
                  <button onClick={() => resetCallCount(contact)}>
                    Reset Count
                  </button>
                )}
                <button onClick={() => handleDeleteContact(contact.id)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showPopup && currentContact && (
        <div className="popup-overlay">
          <div className="popup-content">
            <div className="overlay-container">
              {/* Left Column: Script */}
              <div className="script-column">
                <h3>Call Script</h3>
                <p>
                  {currentContact.first_name || currentContact["First Name"]}?
                </p>
                <p>
                  {currentContact.first_name || currentContact["First Name"]},
                  this is Hamza. I'm getting back to you regarding a form you
                  had filled out on Facebook about state-regulated life
                  insurance programs. You had listed{" "}
                  {currentContact.beneficiary || currentContact.Beneficiary}
                  as your beneficiary. I assume you remember doing that,
                  correct?
                </p>
                <p>
                  Just to confirm, I have your date of birth listed as
                  {currentContact.date_of_birth ||
                    currentContact["Date of Birth"]}
                  , is that right?
                </p>
                <p>
                  Got it, so{" "}
                  {currentContact.first_name || currentContact["First Name"]},
                  is this your first time looking, or are you adding on to what
                  you already have?
                </p>
                <p>Well, what has you looking now?</p>
                <p>
                  Alright, so once again my name is Hamza. I'm a state-licensed
                  underwriter here in Florida. We work with about 35 different
                  A-rated carriers that participate in our programs.
                  {currentContact.first_name || currentContact["First Name"]},
                  my job as the medical underwriter is to ask you a few health
                  and medical-related questions. It will only take a couple of
                  minutes, after that we're going to submit a request to the
                  carrier to see if we can get you qualified. Does that make
                  sense?
                </p>
                <p>
                  Great. Let’s start with a few questions to better understand
                  your situation!
                </p>

                {/* Health Questions */}
                <p>Any history of Tobacco Use?</p>
                <select
                  value={currentContact.tobacco_use || ""}
                  onChange={(e) =>
                    handleFinancialQuestionChange(e, "tobacco_use")
                  }
                >
                  <option value="">Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>

                <p>
                  Any history of Heart Attack or Stroke? (if they answer yes ask
                  them how their heart health is now?)
                </p>
                <select
                  value={currentContact.heart_condition || ""}
                  onChange={(e) =>
                    handleFinancialQuestionChange(e, "heart_condition")
                  }
                >
                  <option value="">Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>

                {currentContact.heart_condition === "Yes" && (
                  <>
                    <p>If yes, did the doctors put any stents in your heart?</p>
                    <select
                      value={currentContact.stents_in_heart || ""}
                      onChange={(e) =>
                        handleFinancialQuestionChange(e, "stents_in_heart")
                      }
                    >
                      <option value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </>
                )}

                <p>Any history of Cancer?</p>
                <select
                  value={currentContact.cancer_history || ""}
                  onChange={(e) =>
                    handleFinancialQuestionChange(e, "cancer_history")
                  }
                >
                  <option value="">Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>

                <p>Anything as far as Diabetes?</p>
                <select
                  value={currentContact.diabetes_history || ""}
                  onChange={(e) =>
                    handleFinancialQuestionChange(e, "diabetes_history")
                  }
                >
                  <option value="">Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>

                {currentContact.diabetes_history === "Yes" && (
                  <>
                    <p>
                      If yes to Diabetes, are you taking insulin, and could you
                      let me know which kinds?
                    </p>
                    <textarea
                      value={currentContact.diabetes_insulin || ""}
                      onChange={(e) =>
                        handleFinancialQuestionChange(e, "diabetes_insulin")
                      }
                    />
                  </>
                )}

                <p>Are you taking any kind of pain medications?</p>
                <textarea
                  value={currentContact.pain_medication || ""}
                  onChange={(e) =>
                    handleFinancialQuestionChange(e, "pain_medication")
                  }
                />

                <p>Anything as far as high blood pressure or cholesterol?</p>
                <textarea
                  value={currentContact.blood_pressure || ""}
                  onChange={(e) =>
                    handleFinancialQuestionChange(e, "blood_pressure")
                  }
                />

                <p>Anything as far as breathing? Asthma or COPD?</p>
                <select
                  value={currentContact.breathing_issues || ""}
                  onChange={(e) =>
                    handleFinancialQuestionChange(e, "breathing_issues")
                  }
                >
                  <option value="">Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>

                <p>Anything as far as your thyroid?</p>
                <textarea
                  value={currentContact.thyroid_issues || ""}
                  onChange={(e) =>
                    handleFinancialQuestionChange(e, "thyroid_issues")
                  }
                />

                <p>Anything as far as anxiety or depression?</p>
                <select
                  value={currentContact.anxiety_depression || ""}
                  onChange={(e) =>
                    handleFinancialQuestionChange(e, "anxiety_depression")
                  }
                >
                  <option value="">Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>

                <p>Any recent hospitalizations or surgeries?</p>
                <textarea
                  value={currentContact.recent_hospitalizations || ""}
                  onChange={(e) =>
                    handleFinancialQuestionChange(e, "recent_hospitalizations")
                  }
                />

                <p>Do you plan on being buried or cremated?</p>
                <select
                  value={currentContact.burial_plan || ""}
                  onChange={(e) =>
                    handleFinancialQuestionChange(e, "burial_plan")
                  }
                >
                  <option value="">Select</option>
                  <option value="Buried">Buried</option>
                  <option value="Cremated">Cremated</option>
                </select>

                <p>
                  Were you primarily looking to cover just final expenses, or
                  were you hoping to leave a little extra behind for a loved
                  one?
                </p>
                <textarea
                  value={currentContact.coverage_purpose || ""}
                  onChange={(e) =>
                    handleFinancialQuestionChange(e, "coverage_purpose")
                  }
                />

                <p>
                  {currentContact.first_name || currentContact["First Name"]},
                  based on everything you've shared, our system is generating
                  three options tailored to your age and health. It’s very
                  important to me that we find something affordable and
                  sustainable for you. Please give me a moment.
                </p>
                <p>
                  Of these options, which one would be the most affordable for
                  you?
                </p>
                <p>
                  Let's go ahead and submit an application to see if we can get
                  you approved for that. There's no cost to check and, if we are
                  able to get you approved, you can then decide what day you
                  want the payments taken out each month.
                </p>

                <p>
                  I've asked you for a lot of your information. So it's very
                  important that you know whom you're speaking with. I’m going
                  to give you three pieces of information:
                  <br></br>#1 my full name
                  <br></br>#2 my National Producer Number
                  <br></br>#3 my personal phone number.
                </p>

                <p>
                  Perfect choice. Where would you like the policy packet to be
                  delivered?
                </p>
                <textarea
                  value={currentContact.delivery_address || ""}
                  onChange={(e) =>
                    handleFinancialQuestionChange(e, "delivery_address")
                  }
                />
                <p>
                  {currentContact.first_name || currentContact["First Name"]},
                  you can expect to receive your policy packet in the mail
                  within about 10 business days. Just to summarize, we've got
                  you approved for $X,XXX in coverage with XXXXXXXXXXX. You can
                  feel at ease knowing
                  {currentContact.beneficiary ||
                    currentContact.Beneficiary}{" "}
                  will be taken care of.
                </p>
              </div>

              {/* Right Column: Notes and Controls */}
              <div className="notes-column">
                <h3>Notes</h3>
                <textarea
                  rows="15"
                  value={currentContact.note || ""}
                  onChange={(e) => {
                    const updatedContact = {
                      ...currentContact,
                      note: e.target.value,
                    };
                    setCurrentContact(updatedContact);
                    handleSaveChanges(updatedContact); // Save changes to Supabase immediately
                  }}
                  placeholder="Enter notes here"
                />

                {/* Call Information */}
                <div className="call-info">
                  <p>
                    Age:{" "}
                    {calculateAge(
                      currentContact.date_of_birth ||
                        currentContact["Date of Birth"]
                    )}
                  </p>
                  <p>Call Count: {currentContact.call_count || 0}</p>
                  {activeConnection && (
                    <p>Call Duration: {callDuration} seconds</p>
                  )}
                </div>

                {/* Action Buttons */}
                <div className="popup-actions">
                  {activeConnection ? (
                    <button onClick={handleHangUp}>Hang Up</button>
                  ) : (
                    <button onClick={() => handleDial(currentContact)}>
                      Dial
                    </button>
                  )}
                  <button onClick={closePopup}>Close</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Dialer;
